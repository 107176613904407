
export class DataTableModel {
  columns!: Array<{}>;
  displayBlock!: DisplayBlock;
  data: any[] | any;
  getUrl: any;
  isServerSidePagination: boolean = false;
  isAllowPaging: boolean = true;
  sortOrder: any = [];
  isShowPaginationInfo: boolean = true;
  isComingFrom!: string
  isLoadData: boolean = false;
  isSearching: boolean = false;
  queryparam: any;
  searchText: any;
  isQueryParam: boolean = false;
  filterValue: any;
}

export type IFormItemType =
  | 'number'
  | 'date'
  | 'email'
  | 'text'
  | 'picklist'
  | 'group-picklist'
  | 'user-picklist'
  | 'name'
  | 'phone'
  | 'country'
  | 'address'
  | 'connected-entity'
  | 'multi-select-picklist'
  | 'icon'
  | 'keyvalue'
  | 'html'
  | 'currency'
  | 'button'
  | 'alert'
  | 'validation-display'
  | 'status'
  | 'level'
  | 'bgColor'
  | 'modifiedBy'
  | 'website'
  | 'action'
  | 'full-name'
  | 'base-location'
  | 'editContent'
  | 'clientTool'
  | 'updatedBy'
  | 'CustomOrgView'
  | 'userRole'
  | 'PhoneNoFormat'
  | 'registrationDateFormat'
  | 'rowObj'
  | 'objName'
  | 'statusButton'
  | 'teamRegistration'
  | 'textWithProfileView'
  | 'textWithImg'
  | 'docStatus'
  | 'deleteAction'
  | 'teamRoster'
  | 'viewDocument'
  | 'nameWithImg'
  | 'arraydata'
  | 'grade'
  | 'ageGradeTpl'
  | 'divisionTpl'
  | 'dateTpl'
  | 'teamOneScoreTpl'
  | 'teamOneTpl'
  | 'teamTwoScoreTpl'
  | 'teamTwoTpl'
  | 'saveBtnTpl'
  | 'teamSubmissionAction'
  | 'weight'
  | 'toggle'
  | 'rowObjNumber'
  | 'discount'
  | 'actionHide'
  | 'actionHide'
  | 'checkbox'
  | 'isOptIn'
  | 'childCommunityAction'
  | 'rowfullNameObj';

export interface DisplayElement {
  type: IFormItemType;
  serviceKey?: string;
  conversionKey?: string;
  name?: string;
  label?: string;
  noLabel?: boolean;
  bold?: boolean;
  description?: string;
  parent?: string;
  required?: boolean;
  hidden?: boolean;
  displayMode?: DisplayModeType;
  link?: (data: any, moment?: any, loggedInUserId?: any) => string;
  format?: (data: any, moment?: any) => string;
  value?: (data: any, moment?: any) => string;
  lookupCodeType?: string;
  dataSourceUrl?: string;
  displayOrder?: number;
  editable?: boolean;
  removable?: boolean;
  pipe?: string;
  multiple?: boolean;
  maxLength?: string;
  validationKey?: string;
  colSize?: string;
  objName?: string;
  arrayName?: any;
  rowObj?: any;
  sorting?: boolean;
  wordWrap?: boolean;
  dateFormat?: string;
  visible?: boolean;
  routingUrl?: string;
  actions?: {
    isEdit?: boolean;
    isDeactivate?: boolean;
    isDelete?: boolean;
    isView?: boolean;
  };
  message?: string;
}

export enum DisplayModeType {
  edit = 'edit',
  view = 'view',
  both = 'both',
}

export interface DisplayBlock {
  key?: any;
  title?: string;
  prefix?: string;
  editable?: boolean;
  serviceKey?: string;
  options?: DataOptions;
  conversionKey?: string;
  loadOnStartUp?: boolean;
  rowRecordId?: string;
  rows?: DisplayElement[];
  validationKey?: string;
  getUrl?: string;
  isServerSidePagination?: boolean;
  isQueryParam?: boolean;
  id?: any;
  isActive?: boolean;
  queryparam?: string;
  isAllowPaging?: boolean;
  sortOrder?: any;
  isShowPaginationInfo?: boolean;
  isLoadData?: boolean;
  isSearching?: boolean;
  searchText?: any;
  isShiftData?: true;
  data?: any[];
  allData?: any[];
  filterValue?: any;
  isFilterOption?: boolean;
  isAddBtn?: boolean;
  isViewDocument?: boolean;
  isExportData?: boolean;
  isComingFrom?: string;
  perPage?: number;
  headerHeight?: number;
  isFilterOrg?: boolean;
  isFilterParentOrg?: boolean;
  isMultiselectOrg?: boolean;
  isDeleteModal?: boolean;
  type?: string;
  staticData?: any[];
  isStatic?: boolean;
  setStep?: Function;
  createEditTeam?: Function;
  gradesData?: any[];
  isAllowDocumentUpload?: boolean;
  isViewRosterFromTeams?: boolean;
  isOptIn?: boolean;
}

export interface DataOptions {
  isGenerate?: boolean;
}
