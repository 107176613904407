<ng-modals
  id="childCommunityPopup"
  [placement]="'modal-center'"
  className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4">
  <div
    class="w-[calc(100vw-3rem)] sm:w-[36rem] bg-white shadow rounded-md dark:bg-[#0D1C2F] flex flex-col">
    <div class="py-10 px-10 sm:px-14 md:px-28 max-h-[calc(theme('height.screen')_-_80px)] overflow-y-auto no-scrollbar">
      <form [formGroup]="childCommunityForm" (ngSubmit)="handleSubmit()">
        <div>
          <h6 class="mb-8 dark:text-[#C9D7E9]">{{selectedChildCommunityData?.id ? 'Edit' : 'Add'}} Child Community</h6>
          <div class="mb-5">
            <div
              class="selectDropdown w-full border border-[#92AFD326] dark:bg-[#1A2F48] rounded-md p-1">
              <ng-select
                [items]="orgClients"
                id="parentCommunity"
                name="parentCommunity"
                bindLabel="name"
                bindValue="id"
                formControlName="parentCommunity"
                [multiple]="false"
                [readonly]="commingFrom === 'createEvent' || commingFrom === 'editCommunity' ? true : false"
                placeholder="Select parent community"
                class="custom-ng-select w-full"
                (change)="handleCommunityChange($event)"
                [clearable]="false">
                <ng-template ng-optgroup-tmp let-item="item">
                  {{ item.name }}
                </ng-template>
              </ng-select>
            </div>
            <div
                *ngIf="parentCommunity.invalid && isFormSubmitted"
                class="invalid-feedback">
                <div
                    *ngIf="parentCommunity.errors?.['required']"
                    class="text-red-500 ml-1 text-[12px] mt-1">
                    Please select parent community.
                </div>
            </div>
          </div>
          
          <div class="mb-5">
            <input
              type="text"
              id="communityName"
              formControlName="communityName"
              name="communityName"
              class="py-[15px] text-[12px] rounded-md form-input border-slate-200 dark:border-zink-500 focus:outline-none disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-[#BCC9DD] dark:bg-[#1C2F45] placeholder:text-slate-400 dark:placeholder:text-[#94A3B8]"
              placeholder="Community Name"
              required />
            <div
              *ngIf="communityName.invalid && isFormSubmitted"
              class="invalid-feedback">
              <div
                *ngIf="communityName.errors?.['required']"
                class="text-red-500 ml-1 text-[12px] mt-1">
                This field is required.
              </div>
            </div>
          </div>
          <div class="mb-5" *ngIf="!isShowAccessCode">
            <input
              type="text"
              id="accessCode"
              (input)="uppercaseInput($event)"
              formControlName="accessCode"
              [maxlength]="6"
              name="accessCode"
              class="py-[15px] text-[12px] rounded-md form-input border-slate-200 dark:border-zink-500 focus:outline-none disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-[#BCC9DD] dark:bg-[#1C2F45] placeholder:text-slate-400 dark:placeholder:text-[#94A3B8]"
              placeholder="Add 6-Digit Access Code"
              required />
            <div
              *ngIf="accessCode.invalid && isFormSubmitted"
              class="invalid-feedback">
              <div
                *ngIf="accessCode.errors?.['required']"
                class="text-red-500 ml-1 text-[12px] mt-1">
                This field is required.
              </div>
              <div
                *ngIf="accessCode.errors?.['minlength']"
                class="text-red-500 ml-1 mt-1">
                The access code can only 6 characters long
              </div>
              <div
                *ngIf="accessCode.errors?.['maxlength']"
                class="text-red-500 ml-1 mt-1">
                The access code can only 6 characters long
              </div>
            </div>
          </div>
          <div class="mb-5">
            <div
              class="file-input-container bg-white dark:bg-[#1c2f45] rounded-[5px]"
              (click)="logoImageInput.click()">
              <input
                [appModalTrigger]="'cropperModal'"
                #logoImageInput
                type="file"
                id="communityLogo"
                name="communityLogo"
                formControlName="communityLogo"
                placeholder="Community Logo"
                accept="image/*"
                (change)="handleChangeImage($event)"
                class="border border-slate-200 rounded-md dark:bg-[#1C2F45] dark:border-[#284365] focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:focus:border-custom-800 cursor-pointer form-file form-file-md" />
              <label
                class="file-label dark:text-[#BCC9DD] text-black text-[12px]"
                >{{ logoImageFileName || 'Community Logo' }}</label
              >
              <button
              type="button"
                class="upload-btn w-20 h-full bg-[#101824] rounded-tr-md rounded-br-md border-y-[1px] border-r-[1px] border-y-zink-500 border-r-zink-500">
                <i
                  class="ri-upload-2-line font-[18px] text-white"
                  aria-label="Upload File"></i>
              </button>
            </div>
            <div class="flex items-center justify-between mt-1">
              <div class="flex items-center">
                @if (imgUploadLoader) {
                  <span
                    class="inline-block size-5 border-2 border-green-500 rounded-full animate-spin border-l-transparent mx-3 mt-1"></span>
                }
              </div>
              @if (logoImageFileName) {
                <div class="flex items-center">
                  <span class="text-red-500 font-medium">
                    <i
                      (click)="clearFileUploadInputValues()"
                      class="ri-close-large-line cursor-pointer fill:text-red-500"></i>
                  </span>
                  <span
                    class="text-black font-medium ml-2 dark:text-white text-black">
                    {{ logoImageFileName }}
                  </span>
                </div>
              }
            </div>
          </div>
          <div class="mb-5 flex">
            <div
              class="relative inline-block w-10 align-middle transition duration-200 ease-in ltr:mr-2 rtl:ml-2">
              <input
                name="isShowChildEvents"
                id="isShowChildEvents"
                type="checkbox"
                (change)="handleChangeToggle($event)"
                [checked]="isShowChildEvents"
                class="absolute block size-5 transition duration-300 ease-linear border-2 rounded-full appearance-none cursor-pointer border-slate-200 dark:border-white bg-white/80 dark:bg-white peer/published checked:bg-white dark:checked:bg-white ltr:checked:right-0 rtl:checked:left-0 checked:bg-none checked:border-[#249782] dark:checked:border-[#249782] arrow-none" />
              <label
                for="isShowChildEvents"
                class="block h-5 overflow-hidden duration-300 ease-linear border rounded-full cursor-pointer cursor-pointertransition border-slate-200 dark:border-zink-500 bg-slate-200 dark:bg-[#9D9D9D] peer-checked/published:bg-[#249782] peer-checked/published:border-[#249782]"></label>
            </div>
            <label
              for="isShowChildEvents"
              class="ml-1 inline-block text-base font-medium cursor-pointer">
              Show child events in the parent community.
            </label>
          </div>
          <button
            type="submit"
            class="w-full my-7 text-[#C9D8E9] font-normal btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
            Save
          </button>
          <div class="text-center mt-5">
            <button (click)="handleCloseModal(false)" class="text-[#F04444]">
              X Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  @if (showCropperModal) {
    <div class="cropper-main-cotainer">
      <app-image-cropper
        [imageChangedEvent]="imageChangedEvent"
        (setCropperImgVal)="setCropperImgVal($event)"
        (handleCloseModel)="handleClear()"
        (handleSaveModal)="handleSaveModal()"
        [fileName]="logoImageFileName"
        [aspectRatio]="aspectRatio" />
    </div>
  }
</ng-modals>